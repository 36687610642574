$RESPONSIVE_BREAKPOINT_PHONE: 500px;
$RESPONSIVE_BREAKPOINT_PHABLET: 768px;
$RESPONSIVE_BREAKPOINT_TABLET: 992px;
$RESPONSIVE_BREAKPOINT_DESKTOP: 1200px;
$AVATAR_HEIGHT: 150px;
$AVATAR_WIDTH: 150px;
$ICON_HEIGHT: 150px;
$ICON_WIDTH: 150px;
$BANNER_HEIGHT: 400px;
$BANNER_WIDTH: 1200px;
@import "style/mixins.sass";

.BobLayout {
  min-height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: row;
  > .SideNav {
    flex: 0 0 unquote('max(20vw, 200px)');
    overflow: auto; }
  > .OneColumn {
    flex: 1 1 auto;
    overflow: auto; }
  &-orgBranding {
    display: flex;
    .OrganizationIcon {
      margin: 0 1em; }
    .Header {
      white-space: nowrap;
      overflow: hidden;
      flex: 1 1;
      text-overflow: ellipsis; } }

  .SideNav-content > .Button:last-child {
    margin-top: auto; } }
